export const INIT = '🎉 INIT'

export const LANGUAGE_TOGGLE = '🎉 LANGUAGE_TOGGLE'

export const PRELOAD_SUCCESS = '✅ PRELOAD_SUCCESS'

export const LINES_REQUEST = '🛎 LINES_REQUEST'
export const LINES_SUCCESS = '✅ LINES_SUCCESS'

export const SCHEDULE_REQUEST = '🛎 SCHEDULE_REQUEST'
export const SCHEDULE_SUCCESS = '✅ SCHEDULE_SUCCESS'

export const FILM_UPDATE_REQUEST = '🛎 FILM_UPDATE_REQUEST'
export const FILM_UPDATE_SUCCESS = '✅ FILM_UPDATE_SUCCESS'

export const PROGRAM_REQUEST = '🛎 PROGRAM_REQUEST'
export const PROGRAM_SUCCESS = '✅ PROGRAM_SUCCESS'

export const roles = {
  director: 'Director',
  script: 'script',
  animation: 'animation',
  dop: 'DoP',
  editing: 'editing',
  music: 'music',
  sound: 'sound',
  production: 'Production',
  contact: 'Contact',
  realization: 'Realization'
}