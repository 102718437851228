import {
  SCHEDULE_REQUEST, SCHEDULE_SUCCESS
} from '../common/constants'

const initialState = {
  data: [],
  loading: false
}

export default function reducer(state = initialState, action: any = {}) {
  const { type, payload } = action
  switch (type) {
    case SCHEDULE_REQUEST:
      return {...state, loading: true}

    case SCHEDULE_SUCCESS:
      return {...state, data: payload.viewings, loading: false}

    default:
      return state
  }
}