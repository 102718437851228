import React from 'react';
import '../css/App.css';

import moment from 'moment'
import 'moment/locale/de';
import { useParams } from 'react-router-dom';
import Listing from '../components/Listing';
import { useSelector } from 'react-redux';
moment.locale('de')

const Location: React.FC = () => {
  const lines = useSelector((state: any) => state.lines.data) || {}
      , viewings = useSelector((state: any) => state.viewings.data)
      , { language } = useSelector((state: any) => state.app)
      , { lineId } = useParams() as any
      // , location = (locations[locationName] || {id: -1, title: {de: '---'}})

  return (
    <article id="post-8255" className="post-8255 page type-page status-publish hentry">
      <header className="entry-header">
        <h1 className="entry-title">{lines[lineId] ? lines[lineId][language] || lines[lineId].de : ''}</h1>
      </header>

      <div className="entry-content">
        {/* <p>
          <img
            className="alignleft size-full wp-image-1611 colorbox-1106"
            alt="PublikumFilmgalerieWEBklein_01"
            src="https://web.archive.org/web/20170308154140im_/http://www.kurzfilmwoche.de/wp-content/uploads/2013/10/PublikumFilmgalerieWEBklein_01.jpg"
            width="170"
            height="113"
          />
        </p>

        <br style={{clear: 'both'}} /> */}
        <Listing viewings={viewings.filter((viewing: any) => viewing.line === lineId)}/>
      </div>

      <footer className="entry-meta">
      </footer>
    </article>
  )
}

export default Location;
