import React from 'react'
import '../css/listing.css'

import { Link } from 'react-router-dom'

import moment from 'moment'
import 'moment/locale/de'
import { useSelector } from 'react-redux'
import { translateProgram, translateSubtitle } from '../common/translation'

interface Props {
  viewings: any
}

const Listing = ({viewings}: Props) => {
  const days: any = {}
      , { data } = useSelector((state: any) => state.lines)
      , { language } = useSelector((state: any) => state.app)

  moment.locale(language || 'de')

  viewings.forEach((viewing: any) => {
    const day = moment(viewing.date).format('dddd, DD.MM.YYYY')
    if (!days[day])
      days[day] = []

    days[day].push(viewing)
  })

  return <>
    {Object.keys(days).map(day =>
      <div className="list">
        <h2 style={{color: 'white'}}>{day}</h2>
        {days[day].map((viewing: any) =>
          <div key={viewing.id} className="item" style={{backgroundColor: (data[viewing.line] || {}).color || '#fff'}}>
            <div className="time" style={{width: 45}}>
              <small>
                {moment(viewing.date).format('HH:mm')}
              </small>
            </div>
            <div className="location" style={{width: 160}}>
              <Link to={`/location/${viewing.location.id}`}>
                {viewing.location.value}
              </Link>
            </div>
            <div className="info" style={{flex: 1}}>
              <Link to={`/program/${viewing.programId}`} style={{display: 'block'}}>
                {translateProgram(viewing.title, language)} {viewing.subtitle ? ' - ' + translateSubtitle(viewing.subtitle, language) : ''}
              </Link>
            </div>
          </div>
        )}
      </div>
    )}
  </>
}

export default Listing;
