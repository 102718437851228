import axios from 'axios'

import lines from './data/lines.json'

// export const getLines = async () =>
//   (await axios.get('/api/lines')).data

// export const getProgram = async (id: number) =>
//   (await axios.get('/api/program/' + id)).data

// export const updateFilm = async (id: number, field: string, value: any) =>
//   (await axios.post('/api/update/' + id + '/' + field, {value})).data

export const getLines = async (): Promise<any> =>
  lines
  // (await axios.get('/api/lines')).data

// export const getSchedule = async (): Promise<any> =>
//   (await axios.get('/schedule.json')).data

// export const getPreload = async (): Promise<any> =>
//   (await axios.get('/programs.json')).data
