import { take, put, fork } from 'redux-saga/effects'

import { getLines } from '../api'
import { LINES_REQUEST, LINES_SUCCESS } from '../common/constants'

function* fetch(id: number): any {
  try {
    yield put({
      type: LINES_SUCCESS,
      payload: yield getLines()
    })
  } catch(e) {
    console.log('lines', e)
    /* Errors are handled via Redux reducers */}
}

export default function* linesSaga() {
  while (true) {
    // Wait until we get a schedule request
    const { payload } = yield take(LINES_REQUEST)

    // .. and process it in the 'background'
    yield fork(fetch, payload)
  }
}