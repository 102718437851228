import React from 'react'
import { Link } from "react-router-dom"
import { translate } from '../common/translation'

interface Props {
  language: 'de' | 'en'
}

const BackLink = ({language}: Props) =>
  <Link to={`/`} style={{display: 'block', textAlign: 'center', color: 'white', marginTop: 10, fontSize: '12pt'}}>
    {translate('Back to Overview', language)}
  </Link>

export default BackLink
