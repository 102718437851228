import React from 'react';
import '../css/App.css';
import BackLink from '../components/BackLink'

import moment from 'moment'
import 'moment/locale/de'
import { useParams } from 'react-router-dom'
import Listing from '../components/Listing'
import { useSelector } from 'react-redux'
moment.locale('de')

const locations = {
  '3': {
    id: 3,
    title: {
      de: 'Ostentor'
    }
  },
  '2': {
    id: 2,
    title: {
      de: 'Filmgalerie im Leeren Beutel',
    },
  },
  '4': {
    id: 4,
    title: {
      de: 'Wintergarten',
    }
  },
  '5': {
    id: 5,
    title: {
      de: 'W1 - Theatersaal',
    }
  },
  '8': {
    id: 8,
    title: {
      de: 'Leerer Beutel',
    }
  },

} as any

const Location: React.FC = () => {
  const language = useSelector((state: any) => state.app.language)
      // , lines = useSelector((state: any) => state.lines.data)
      , viewings = useSelector((state: any) => state.viewings.data)
      , { locationId } = useParams() as any
      , location = (locations[locationId] || {id: -1, title: {de: '---'}})

  return (
    <article>
      <BackLink language={language}/>

      <header>
        <h1>{location.title.de}</h1>
      </header>

      <div className="entry-content">
        {/* <p>
          <img
            className="alignleft size-full wp-image-1611 colorbox-1106"
            alt="PublikumFilmgalerieWEBklein_01"
            src="https://web.archive.org/web/20170308154140im_/http://www.kurzfilmwoche.de/wp-content/uploads/2013/10/PublikumFilmgalerieWEBklein_01.jpg"
            width="170"
            height="113"
          />
        </p>

        <br style={{clear: 'both'}} /> */}
        <Listing viewings={viewings.filter((viewing: any) => viewing.location.id === locationId)}/>
      </div>

      <footer className="entry-meta">
      </footer>
    </article>
  )
}

export default Location;
