import React from 'react'
// import { translate } from '../common/translation'

interface Props {
  language: 'de' | 'en'
}

const Navigation = ({language}: Props) =>
  <div className="navigation">
    {/*<a href="/kurzfilmwoche.2020.pdf" target="_blank">
      {translate('Catalog as PDF', language)}
    </a>*/}
  </div>

export default Navigation


