import React from 'react'
import '../css/listing.css'

import { Link } from 'react-router-dom'

import moment from 'moment'
import 'moment/locale/de'
import { useSelector } from 'react-redux'
import { translateProgram, translateSubtitle } from '../common/translation'

const filterIds = [ 
  212,
  184,
  179,
  180,
  211,
  185,
  178,

  220,
  202,

  218, // Preisträger
  217, // - Publikum
]

const LineListing = () => {
  const days: any = {}
      , { data } = useSelector((state: any) => state.lines)
      , { language } = useSelector((state: any) => state.app)

  moment.locale(language || 'de')

  const listPrograms = (programs: any, color: string) => 
    programs.filter((p: any) => !filterIds.includes(p.id)).sort((a: any, b: any) => a.name.localeCompare(b.name)).map((program: any) =>
      <div key={program.id} className="item" style={{backgroundColor: color || '#fff'}}>
        {/* <div className="time" style={{width: 45}}>
          <small>
            {moment(viewing.date).format('HH:mm')}
          </small>
        </div> */}
        {/* <div className="location" style={{width: 160}}>
          <Link to={`/location/${viewing.location.id}`}>
            {viewing.location.value}
          </Link>
        </div> */}
        <div className="info" style={{flex: 1}}>
          <Link to={`/program/${program.id}`} style={{display: 'block'}}>
            {program.name}
          </Link>

          {/* <Link to={`/program/${line.programId}`} style={{display: 'block'}}>
            {translateProgram(viewing.title, language)} {viewing.subtitle ? ' - ' + translateSubtitle(viewing.subtitle, language) : ''}
          </Link> */}
        </div>
      </div>
    )

  return <>
    {Object.keys(data).filter(id => !data[id].parent).map(id =>
      <div className="list">
        <h2 style={{color: 'white'}}>{data[id][language] || data[id].de}</h2>
        {Object.keys(data).filter(i => `${data[i].parent}` === `${id}`).map(i =>
          <>
            <h3>{data[i][language] || data[i].de}</h3>
            {listPrograms(data[i].programs, data[i].color)}
          </>
        )}

        {listPrograms(data[id].programs, data[id].color)}
      </div>
    )}
  </>
}

export default LineListing;
