import {
  PROGRAM_REQUEST, PROGRAM_SUCCESS, PRELOAD_SUCCESS
} from '../common/constants'

const initialState: any = {}

export default function reducer(state = initialState, action: any = {}) {
  const { type, payload } = action
  switch (type) {
    case PROGRAM_REQUEST:
      const program: any = {}
      program[payload] = {
        ...(state[payload] || {}),
        loading: true
      }
      return {...state, ...program}

    case PRELOAD_SUCCESS:
      const fresh: any = {}
      payload.forEach((film: any) => fresh[film.id] = film)
      return fresh

    case PROGRAM_SUCCESS:
      const tmp: any = {}
      tmp[payload.id] = {
        ...payload,
        loading: false
      }
      return {...state, ...tmp}

    default:
      return state
  }
}
