const translations: any = {
  languages: {
    'Aboriginal': 'Aboriginal',
    'Albanian': 'albanische',
    'Arabic': 'arabische',
    'Azerbaijani': 'aserbaidschanische',
    'Bengali': 'bengalische',
    'Cantonese': 'kantonesische',
    'Catalan': 'katalanische',
    'Chinese': 'chinesische',
    'Croatian': 'kroatische',
    'Dutch': 'niederländische',
    'English': 'engl.',
    'Estonian': 'estnische',
    'Filipino': 'philippinische',
    'Finnish': 'finnische',
    'Flemish': 'flämische',
    'French': 'französische',
    'German': 'deutsche',
    'Indonesian': 'indonesische',
    'Italian': 'italienische',
    'Khmer': 'Khmer',
    'Korean': 'koreanische',
    'Lingala': 'Lingala',
    'Malay': 'malaysische',
    'Mandarin': 'Mandarin',
    'Persian (Farsi)': 'Farsi',
    'Polish': 'polnische',
    'Portuguese': 'portugiesische',
    'Romanian': 'rumänische',
    'Russian': 'russische',
    'Spanish': 'spanische',
    'Swedish': 'schwedische',
    'Swiss-german': 'deutsche',
    'Turkish': 'türkische',
    'Ukrainian': 'ukrainische',
    'Ukranian': 'ukrainische',
    'Vietnamese': 'vietnamesische',
  },
  countries: {
    'Albania': 'Albanien',
    'Argentina': 'Argentinien',
    'Australia': 'Australien',
    'Austria': 'Österreich',
    'Belarus': 'Weißrussland',
    'Belgium': 'Belgien',
    'Brazil': 'Brasilien',
    'Cambodia': 'Kambodscha',
    'Canada': 'Kanada',
    'Canada, Quebec': 'Kanada, Quebec',
    'China': 'China',
    'Colombia': 'Kolumbien',
    'Croatia': 'Kroatien',
    'Democratic Republic of the Congo': 'Demokratische Republik Kongo',
    'Denmark': 'Dänemark',
    'Ecuador': 'Ecuador',
    'Estonia': 'Estland',
    'Finland': 'Finnland',
    'France': 'Frankreich',
    'Germany': 'Deutschland',
    'Hong Kong': 'Hong Kong',
    'Hungary': 'Ungarn',
    'Iceland': 'Island',
    'Indonesia': 'Indonesien',
    'Iran': 'Iran',
    'Ireland': 'Irland',
    'Italy': 'Italien',
    'Kosovo': 'Kosovo',
    'Luxembourg': 'Luxemburg',
    'Macedonia': 'Mazedonien',
    'Malaysia': 'Malaysia',
    'Mexico': 'Mexiko',
    'Morocco': 'Marokko',
    'New Zealand': 'Neuseeland',
    'Norway': 'Norwegen',
    'Philippines': 'Philippinen',
    'Poland': 'Polen',
    'Portugal': 'Portugal',
    'Russia': 'Russland',
    'Serbia': 'Serbien',
    'Singapore': 'Singapore',
    'Slovak republic': 'Slowakische Republik',
    'Slovakia': 'Slowakei',
    'South Korea': 'Südkorea',
    'Spain': 'Spanien',
    'Sweden': 'Schweden',
    'Switzerland': 'Schweiz',
    'Taiwan': 'Taiwan',
    'The Netherlands': 'Niederlande',
    'Ukraine': 'Ukraine',
    'United Kingdom': 'Vereinigtes Königreich',
    'United States': 'Vereinigte Staaten',
    'Vietnam': 'Vietnam'
  },
  programs: {
    'Architekturfenster': 'Window to Architecture',
    'Bayernfenster': 'Window to Bavaria',
    'Bühne frei: Studium Medientechnik an der Technischen Hochschule Deggendorf': 'Clear the stage: Media technology studies at the Technical University of Deggendorf',
    'Deutscher Wettbewerb': 'German Competition',
    'Digital Fairy Tales': 'Digital Fairy Tales',
    'Donaublut': 'Donaublut',
    'Eröffnungsfeier & Filme': 'Opening Ceremony & Films',
    'Eröffnungsfilmprogramm': 'Opening film programme',
    'Eröffnungsparty': 'Opening party',
    'Film & Gespräch: Adnan Softíc': 'Film & Talk: Adnan Softíc',
    'Internationaler Wettbewerb': 'International Competition',
    'Kinokneipe': 'Kinokneipe',
    'Kleine Ukraine - Ganghofersiedlung': 'Little Ukraine - Ganghofersiedlung',
    'Midissage Ausstellung Alla Churikova': 'Midissage exhibition Alla Churikova',
    'Midnight Movies Jubiläum': 'Midnight Movies Anniversary',
    'Natur': 'Nature',
    'Partyfilme': 'Party Movies',
    'Plattenfilme': 'Plattenfilme',
    'Poetry in Motion': 'Poetry in Motion',
    'Preisträgerfilme': 'Award winning films',
    'Publikumslieblinge': 'Audience favorites',
    'Regionalfenster': 'Window to the Region',
    'SchülerInnen-Lieblinge': 'Pupils\' favorites',
    'Tablet-Filmwerkstatt für Kinder': 'Tablet film workshop for children',
    'Tanzfilme': 'Dance Movies',
    'Ukraine': 'Ukraine',
    'VR Workshop': 'VR Workshop',
    'Vernissage W1 Natur-Ausstellung mit Keynote mit Tamiko Thiel': 'Vernissage W1 nature exhibition with keynote with Tamiko Thiel',
    'Werkschau Alla Churikova': 'Retrospective Alla Churikova'
  },
  strings: {
    'color & b/w': 'Farbe & s/w',
    'color': 'Farbe',
    'b/w': 's/w',
    'no dialog': 'kein Dialog',
    'Engl. subtitles': 'engl. Untertitel',
    'Contact': 'Kontakt',
    'Production': 'Produktion',

    'Director': 'Regie',
    'Script': 'Skript',
    'Animation': 'Animation',
    'DoP': 'Kamera',
    'Editing': 'Editing',
    'Music': 'Musik',
    'Sound': 'Sound',
    'Realization': 'Realisation',

    'Events in 2020': 'Ursprüngliche Veranstaltungen in 2020',
    'Events in 2021': 'Veranstaltungen in 2021',
    'CALL FOR ENTRIES': 'AUFRUF ZUR EINREICHUNG',
    'INFORMATION': 'INFORMATION',
    'Buy tickets online': 'Karten online kaufen',
    'Catalog as PDF': 'Katalog als PDF',
    'Back to Overview': 'Zurück zur Übersicht',

    'Update': 'Absage sämtlicher Veranstaltungen'
  }
}
export default translations

export const translateArray = (type: 'countries' | 'languages', strings: any, language: 'de' | 'en') =>
  language === 'en' ? strings : strings.map((key: string) => translations[type][key])

export const translate = (key: string, language: 'de' | 'en') =>
  language === 'en' ? key : translations.strings[key]

export const translateProgram = (title: string, language: 'de' | 'en') => {
  if (language === 'de')
    return title
  const [, line, number] = title.match(/^(.+?)([\s\d]*)$/) as any
  return (translations.programs[line] || line) + number
}

export const translateSubtitle = (subtitle: string, language: 'de' | 'en') =>
  subtitle.indexOf(' / ') === -1 ? subtitle : subtitle.split(/ \/ /)[language === 'de' ? 0 : 1]
