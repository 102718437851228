import { all } from 'redux-saga/effects'

import Lines from './lines'
// import Schedule from './schedule'
// import Preloader from './preloader'

export default function* rootSaga () {
  yield all([
    // Preloader(),
    // Schedule()
    Lines()
  ])
}