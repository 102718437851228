import { combineReducers } from 'redux'
import lines from './lines'
import programs from './programs'
import viewings from './viewings'
import app from './app'

export default combineReducers({
  app,
  lines,
  programs,
  viewings
})