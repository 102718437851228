import {
  LINES_REQUEST, LINES_SUCCESS
} from '../common/constants'

const initialState = {
  data: [],
  loading: false
}

export default function reducer(state = initialState, action: any = {}) {
  const { type, payload } = action
  switch (type) {
    case LINES_REQUEST:
      return {...state, loading: true}

    case LINES_SUCCESS:
      const data: any = {...state.data}
      payload.forEach((line: any) => data[line.id] = line)
      return {...state, data, loading: false}

    default:
      return state
  }
}