import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LANGUAGE_TOGGLE } from '../common/constants'

const LanguageSwitch = () => {
  const dispatch = useDispatch()
      , { language } = useSelector((state: any) => state.app)

  return <div style={{position: 'absolute', top: 25, right: 25}}>
    <a href={'#' + (language === 'de' ? 'en' : 'de')} style={{color: 'white'}} onClick={() => {
      dispatch({type: LANGUAGE_TOGGLE})
      return false
    }}>
      {language === 'de' ? 'English' : 'Deutsch'}
    </a>
  </div>
}

export default LanguageSwitch