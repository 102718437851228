import React from 'react'
import '../css/App.css';
import { translateArray, translate, translateProgram, translateSubtitle } from '../common/translation'

import moment from 'moment'
import 'moment/locale/de'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BackLink from '../components/BackLink'
import programdata from '../data/programs.json'

import { useTitle } from 'react-use';
moment.locale('de')


const roles = {
  director: 'Director',
  script: 'Script',
  animation: 'Animation',
  dop: 'DoP',
  editing: 'Editing',
  music: 'Music',
  sound: 'Sound',
  production: 'Production',
  contact: 'Contact',
  realization: 'Realization'
}

const generateContacts = (contacts: any[]) =>
  contacts.map(contact => {
    let fields = contact.company ? [contact.company] : []
    if (contact.name)
      fields.push(contact.name)

    return fields.join(', ')
  }).join(', ')

const generateCredits = (film: any, language: 'de' | 'en', disableSubtitles: boolean = false) =>
  <>
    {film.countries.length ? translateArray('countries', film.countries, language).join('/') + ' ' : false}
    {film.year ? film.year : ''},{' '}
    {film.color ?
      <span>{film.color === 'Both' ? translate('color & b/w', language) : film.color === 'Black & white' ? translate('b/w', language) : translate('color', language)}, </span> :
      false
    }
    {film.duration} min,{' '}
    {!film.audio ? false :
      film.audio.none ? translate('no dialog', language) :
      film.audio.ov ? 'OV' :
      translateArray('languages', film.audio.languages, language).join('/') + ' OV'
    }
    {!disableSubtitles && film.audio && film.audio.subtitles &&
      <span>
      , {translate('Engl. subtitles', language)}
      </span>
    }
    <br/>
    {film.credits && film.credits.length ? film.credits.map((credit: any) => {
      const [types, names] = credit
      // @ts-ignore
      return types.map(t => translate(roles[t], language)).join(' & ') + ': ' + names.join(', ')
    }).join(', ') + '\n' : false}
    <br/>
    {!film.production || !film.production.length ? false :
      film.contact && film.contact.length && film.contact[0].sameAsProduction ? <><span>{translate('Production', language)} &amp; {translate('Contact', language)}: {generateContacts(film.contact)}</span><br/></> :
      <><span>{translate('Production', language)}: {generateContacts(film.production)}</span><br/></>
    }

    {film.contact && film.contact.length && !film.contact[0].sameAsProduction ? <span>{translate('Contact', language)}: {generateContacts(film.contact)}</span> :
      false
    }
  </>

const Program: React.FC = () => {
  const { id } = useParams() as any
      , program = programdata.find(p => p.id === parseInt(id)) as any
      // , program = useSelector((state: any) => state.programs[id || '-1'])
      , { language } = useSelector((state: any) => state.app)
      , disableSubtitles = !program || program.title === 'Donaublut' || program.title.match(/Regionalfenster/)

  useTitle(program ? program.title : '')

  // useEffect(() => {
  //   dispatch({type: PROGRAM_REQUEST, payload: id})
  // }, [dispatch])

  return !program || !program.id ? <div>loading</div> : (
    <article>
      <BackLink language={language} />
      <header>
        <h1>
          {translateProgram(program.title, language)}
          {!!program.subtitle && <><br/>{translateSubtitle(program.subtitle, language)}</>}
        </h1>
      </header>

      <div>
        {program.description[language].short &&
          <div dangerouslySetInnerHTML={{__html: program.description[language].short}} style={{
            color: 'white',
            fontWeight: 'bold'
          }}/>
        }

        {/* <p style={{paddingLeft: 0, color: '#fff', fontWeight: 'bold', lineHeight: 1.6}}>
          {program.viewings.map((viewing: any) =>
            <>
              {viewing}
              <br/>
            </>
          )}
        </p> */}
        <div style={{clear: 'both'}} />
        {program.films.filter((f: any) => f.synopsis.de || f.synopsis.en).map((film: any) =>
          <>
            <h2 style={{wordWrap: 'break-word', whiteSpace: 'normal'}}>{((typeof film.title) === 'string' ? film.title : '').toUpperCase()}</h2>
            {(typeof film.englishTitle) === 'string' && <><br/><h2 style={{wordWrap: 'break-word', whiteSpace: 'normal'}}>{(film.englishTitle || ' ').toUpperCase()}</h2></>}
            <div style={{display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20}}>
              <div style={{width: 260}}>
                {!!film.image && <img
                  src={`/240/${film.id}.png`}
                  width="240"
                  alt=""
                />}
              </div>
              <div style={{flex: 1, minWidth: 200}}>
                <span style={{color: '#fff'}}>
                {(film.synopsis[language] || '').replace(/&nbsp;/g, ' ')}
                </span>
                {film.synopsis[language] && <p style={{marginTop: 6}}>—</p>}
                <em style={{color: '#fff'}}>
                  {generateCredits(film, language, !!disableSubtitles)}
                </em>
              </div>
            </div>
            <div style={{clear: 'both'}} />
          </>
        )}
      </div>
    </article>
  );
}

export default Program;
