import React from 'react'
import './css/App.css'

import Location from './scenes/Location'
import Program from './scenes/Program'
import Overview from './scenes/Overview'
import Line from './scenes/Line'

import { Provider } from 'react-redux'
import { store } from './common/store'

import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"
import ScrollToTop from './components/ScrollToTop'
import LanguageSwitch from './components/LanguageSwitch'

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <LanguageSwitch />
        <Switch>
        <Route path="/location/:locationId">
            <Location />
          </Route>
          <Route path="/line/:lineId/:lineName">
            <Line />
          </Route>
          <Route path="/program/:id">
            <Program />
          </Route>
          <Route path="/program">
            <Overview />
          </Route>
          <Route path="/">
            <Overview />
          </Route>
        </Switch>
      </Router>
    </Provider>
  )
}

export default App