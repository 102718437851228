import storage from 'redux-persist/lib/storage'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { persistStore, persistReducer } from 'redux-persist'

import reducers from '../reducers'
import sagas from '../sagas'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'app',
    'lines',
    'viewings',
    'programs'
  ]
}

const persistedReducer = persistReducer(persistConfig, reducers)
    , sagaMiddleware = createSagaMiddleware()
    , composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    , middleware = [sagaMiddleware]
    , enhancer = composeEnhancers(applyMiddleware(...middleware))

export const store = createStore(persistedReducer, enhancer)
export const persistor = persistStore(store)

sagaMiddleware.run(sagas)
