import React, { useEffect } from 'react'
import LineListing from '../components/LineListing'
import Navigation from '../components/Navigation'
import { useTitle } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { LINES_REQUEST } from '../common/constants'
import { translate } from '../common/translation'

const Overview = () => {
  const dispatch = useDispatch()
    , { language } = useSelector((state: any) => state.app)

  useTitle('Kurzfilmwoche')
  useEffect(() => {
    dispatch({type: LINES_REQUEST})
  }, [dispatch])

  return (
    <article>
      {/* <header>
        <h1 style={{marginBottom: 0}}>
          {translate('INFORMATION', language)}
        </h1>
      </header> */}

      {/* <div style={{marginLeft: 80, marginRight: 80, marginTop: 20, marginBottom: 20, borderColor: 'rgb(255, 211, 0)', borderWidth: 2, backgroundColor: '#fff', color: '#000', padding: 50}}>
        {language === 'de' ? <>
          In Kürze findet Ihr hier unser Programm für 2021.<br/>
          <br/>
          Herzlichst, <br/>
          eure KURZFILMWOCHE REGENSBURG
        </> :
        <>
          Our program for 2021 will be available in a few days.<br/>
          <br/>
          Sincerely, <br/>
          Your International Short Film Week Regensburg
        </>
      }
      </div> */}

      <header>
        <h1 style={{marginBottom: 0}}>
          {translate('Events in 2021', language)}
        </h1>
        <br/>
        <em style={{color: 'white', marginTop: 20}}>Alle Veranstaltungen sind vom 21.05. bis zum 06.06. auch online verfügbar!</em>

        <Navigation language={language}/>
      </header>
      <div>
        <LineListing />
      </div>
    </article>
  )
}

export default Overview