import {
  LANGUAGE_TOGGLE
} from '../common/constants'

const initialState = {
  language: 'de'
}

export default function reducer(state = initialState, action: any = {}) {
  const { type } = action
  switch (type) {
    case LANGUAGE_TOGGLE:
      return {...state, language: state.language === 'de' ? 'en' : 'de'}

    default:
      return state
  }
}